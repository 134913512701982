import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import TheWeb from '../learningmodules/theweb';
import loader from '../../images/loader.png';
import Editor  from '../editors/playground';
import Upload  from '../upload';
import Uploader from '../editors/upload-pg.js';
import QuizA from '../quiz-dir/who-am-i-assessments';
import QuizB from '../quiz-dir/who-am-i-assessments-2';
import EditorQuizA from '../quiz-dir/editorQuiz-assessments-1';
import EditorQuizB from '../quiz-dir/editorQuiz-assessments-2';
import EditorQuizC from '../quiz-dir/Interface-Quiz.js';
import EditorQuizD from '../quiz-dir/Interface-Quiz.js';
import EditorA from '../editors/playground-assessments-1';
import EditorB from '../editors/playground-assessments-2';
import EditorUploadA from '../editors/pg-assess-upload-1.js';
import EditorUploadB from '../editors/pg-assess-upload.js';
import Submission from '../task-completion'


 class assessments extends Component {

        constructor(props){
        	super(props);

        	this.state={
           assessmentRef:"",
           taskRef:"", 
           tag:"",
           question:"",
           optionA:"",
           optionB:"",
           optionC:"",
           visual:"",
           codeSnippet:"",
           taskDescription:"",
           taskA:"",
           taskB:"",
           taskC:"",
           taskD:"",
           taskE:"",
           taskF:"",
           taskG:"",
           bookName:""
        	}
              this.fetchQuizResource = this.fetchQuizResource.bind(this);

        }


    async componentWillMount(){ 
      console.log('will mount')
    } 


    async componentDidMount(){ 
       const { match:{ url, params }} = this.props; 
        console.log(params)
        await this.fetchQuizResource()
       
    }

   // async shouldComponentUpdate(prevProps, prevState){
    //	const {match:{ url, params }} = this.props;
     //   console.log(this.state.ref)
      //  console.log(prevState.ref)
    //}

    async componentWillReceiveProps(nextProps){
       const {match:{ url, params }} = this.props;              
    }

   async componentDidUpdate(prevProps){
    const {match:{ url, params }} = this.props;
    console.log(params.taskRef)
    console.log(prevProps.match.params.taskRef)

      if (prevProps.match.params.taskRef != params.taskRef){   
      await this.fetchQuizResource()     
    }

   	   // console.log(params.resourceRef)
   	   // console.log(prevProps.match.params.resourceRef)

 // if(params.resourceRef !=  && params.resourceRef ){
   //     await this.fetchResource();
         //console.log('receive props')
   
     //  }
     }


     async fetchQuizResource(){

       const { match: { params } } = this.props;

         console.log(params)
      const requestResource = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${params.taskRef}/quizAssessmentTask`, {
      method:"POST", 
      headers:{
       "Content-Type" : "application-json"
      },
      mode:"cors"
     })
     .then(response => response.json())
     .then(response => {console.log(response)


     if(response.type === false) {
       this.setState({ 
                  question:response.task[0].question,
                  optionA: response.task[0].optionA,
                  optionB: response.task[0].optionB,
                  optionC: response.task[0].optionC, 
                  visual:  response.task[0].visual,
                  tag: response.task[0].tag,
                  assessmentRef: response.task[0].assessmentRef,
                  codeSnippet: response.task[0].codeSnippet,
                  bookName: response.task[0].bookName             
            }, () => {

               this.props.onBookNameUpdate(this.state.bookName)
               this.props.onAssessmentTagUpdate(this.state.tag)
               this.props.onAssessmentLinkUpdate(this.state.assessmentRef)
            })

     }else{

      this.setState({ 
          codeSnippet:response.task[0].codeSnippet,
          taskDescription:response.task[0].taskDescription,
          taskA:response.task[0].taskA,
          taskB:response.task[0].taskB,
          taskC:response.task[0].taskC,
          taskD:response.task[0].taskD,
          taskE:response.task[0].taskE,
          taskF:response.task[0].taskF,
          taskG:response.task[0].taskG,
          tag: response.task[0].tag,
          assessmentRef: response.task[0].assessmentRef,
          bookName: response.task[0].bookName  
      }, ()=> {
         this.props.onBookNameUpdate(this.state.bookName)
         this.props.onAssessmentTagUpdate(this.state.tag)
         this.props.onAssessmentLinkUpdate(this.state.assessmentRef)

      })



       console.log(this.state.codeSnippet)
     }

     })
}


    render(){

      const { match: { params } } = this.props;

      console.log('i ran')
      console.log(this.props)
    //console.log(this.state.ref)
    console.log(params.taskRef)

    //check state has been updated with new values, if not return loader

    	//if(this.state.taskRef != params.taskRef){ 
      //		return <div className="me"> </div> 
      //	}

    //return canvas element if state has been updated with new values

      return(


      	<div className="you">
         
      	{ (() => {
      		//console.log('i ran')
       switch(params.taskRef){  
        case 'the-web':
           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'the-internet':
           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'text-publishing':
           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'html-designing':
           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-a':
           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-b':
           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-c':
           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-d':
           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-e':
           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-f':
           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
        case 'tag-identification-g':
           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;      
        case 'editor-task-1':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-2':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-3':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-4':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-5':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-6':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-7':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-8':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-9':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-10':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-11':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-12':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-13':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-14':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-15':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-16':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-17':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-18':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-19':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-20':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-21':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-22':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-23':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-24':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-25':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-26':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-27':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-28':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-29':
           return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'editor-task-30':
           return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} htmlChange={this.props.onHtmlChange} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />; 
        case 'submit-assessment':
           return <Submission taskRef={params.taskRef}  />;
           case 'the-web-exam':
            return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'the-internet-exam':
            return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'text-publishing-exam':
            return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'html-designing-exam':
            return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-a-exam':
            return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-b-exam':
            return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-c-exam':
            return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-d-exam':
            return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-e-exam':
            return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-f-exam':
            return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'tag-identification-g-exam':
            return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;      
         case 'editor-task-2-exam':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-3-exam':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-5-exam':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-6-exam':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-7-exam':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-14-exam':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-15-exam':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-20-exam':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} htmlChange={this.props.onHtmlChange} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />; 
            case 'the-web-lagoon-exam':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'the-internet-lagoon-exam':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'text-publishing-lagoon-exam':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'html-designing-lagoon-exam':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-a-lagoon-exam':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-b-lagoon-exam':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-c-lagoon-exam':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-d-lagoon-exam':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-e-lagoon-exam':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-f-lagoon-exam':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
            case 'tag-identification-g-lagoon-exam':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;      
            case 'editor-task-2-lagoon-exam':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName}/>;
            case 'editor-task-3-lagoon-exam':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName}/>;
            case 'editor-task-5-lagoon-exam':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'editor-task-6-lagoon-exam':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'editor-task-7-lagoon-exam':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'editor-task-14-lagoon-exam':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'editor-task-15-lagoon-exam':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'editor-task-20-lagoon-exam':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;   
            case 'editor-task-aa':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-ab':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'tag-positions-aa':
            return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'tag-positions-ab':
            return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'tag-positions-ac':
            return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'tag-positions-ad':
            return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName}  />;
         case 'tag-positions-ae':
               return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'tag-positions-af':
               return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName}  />;
         case 'editor-task-ac':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-ad':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-ae':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;             
         case 'editor-task-af':
                  return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;                
         case 'editor-task-ag':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;                      
         case 'editor-task-ah':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-ai':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-aj':
            return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-ak':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-al':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'editor-task-am':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;               
         case 'page-organisation-a':
               return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'page-organisation-b':
               return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'page-organisation-c':
               return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'page-organisatiom-d':
               return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'page-organisation-e':
               return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'page-organisation-f':
               return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'interface-exam-1':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-2':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-3':
               return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-4':
               return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;                                                                                                                                                                                                  
         case 'interface-exam-5':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-6':
                  return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-7':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-8':
                  return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-9':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-10':
                  return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-11':
                  return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-12':
                  return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-13':
                  return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-14':
                  return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'interface-exam-15':
                  return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;                
         case 'interface-exam-16':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;             
         case 'interface-exam-17':
                  return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;                
         case 'interface-exam-18':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;                      
         case 'interface-exam-1-d':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-2-d':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-3-d':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-4-d':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;                                                                                                                                                                                                  
         case 'interface-exam-5-d':
                        return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-6-d':
                        return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-7-d':
                        return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-8-d':
                        return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-9-d':
                        return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-10-d':
                        return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-exam-11-d':
                        return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-12-d':
                        return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-13-d':
                        return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-exam-14-d':
                        return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
         case 'interface-exam-15-d':
                        return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;                
         case 'interface-exam-16-d':
                        return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;             
         case 'interface-exam-17-d':
                        return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;                
         case 'interface-exam-18-d':
                        return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;                      
        
         case 'interface-1':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-2':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-3':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-4':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;                                                                                                                                                                                                  
         case 'interface-5':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-6':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-7':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-8':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-9':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-10':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-11':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-12':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;   
         case 'interface-13':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-1-c':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-2-c':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-3-c':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-4-c':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;                                                                                                                                                                                                  
         case 'interface-5-c':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-6-c':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-7-c':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-8-c':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-9-c':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-10-c':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'interface-11-c':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'interface-12-c':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;   
         case 'interface-13-c':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-1-d':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-2-d':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-3-d':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-4-d':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-5-d':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'lagoon-p4-6-d':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'lagoon-p4-7-d':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'lagoon-p4-8-d':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'lagoon-p4-9-d':
               return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
               case 'lagoon-p4-10-d':
                  return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
           case 'lagoon-p4-11-d':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-12-d':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-13-d':
                     return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-14-d':
                     return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p4-15-d':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'lagoon-p4-16-d':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
               case 'lagoon-p4-17-d':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                  case 'lagoon-p4-18-d':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                     case 'lagoon-p5-1-d':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p5-2-d':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p5-3-d':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p5-4-d':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
         case 'lagoon-p5-5-d':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
        case 'lagoon-p5-6-d':
         return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
         case 'lagoon-p5-7-d':
            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
            case 'lagoon-p5-8-d':
               return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
               case 'lagoon-p5-9-d':
                  return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                  case 'lagoon-p5-10-d':
                     return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                     case 'lagoon-p5-11-d':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p5-12-d':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p5-13-d':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p5-14-d':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p5-15-d':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                     case 'lagoon-p5-16-d':
                        return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                        case 'lagoon-p5-17-d':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                           case 'lagoon-p5-18-d':
                              return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                              case 'lagoon-p6-1-d':
                                       return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p6-2-d':
                                       return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p6-3-d':
                                       return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p6-4-d':
                                       return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                  case 'lagoon-p6-5-d':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                     case 'lagoon-p6-6-d':
                        return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                        case 'lagoon-p6-7-d':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                           case 'lagoon-p6-8-d':
                              return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                              case 'lagoon-p6-9-d':
                                 return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'lagoon-p6-10-d':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'lagoon-p6-11-d':
                                       return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'lagoon-p6-12-d':
                                       return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'lagoon-p6-13-d':
                                       return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'lagoon-p6-14-d':
                                       return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'lagoon-p6-15-d':
                              return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                              case 'lagoon-p6-16-d':
                                 return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'lagoon-p6-17-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'lagoon-p6-18-d':
                                       return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
       
                              case 'gmates-p4-1-d':
                                          return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                              case 'gmates-p4-2-d':
                                          return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                              case 'gmates-p4-3-d':
                                          return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                              case 'gmates-p4-4-d':
                                          return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                              case 'gmates-p4-5-d':
                                 return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'gmates-p4-6-d':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'gmates-p4-7-d':
                                       return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'gmates-p4-8-d':
                                          return <EditorUploadA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'gmates-p4-9-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p4-10-d':
                                       return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                case 'gmates-p4-11-d':
                                 return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
                                case 'gmates-p4-12-d':
                                 return <EditorQuizC  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
                                 case 'gmates-p4-13-d':
                                    return <EditorQuizD  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
                                    case 'gmates-p4-14-d':
                                       return <EditorQuizC resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
                                       case 'gmates-p3-1-d':
                                             return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'gmates-p3-2-d':
                                             return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'gmates-p3-3-d':
                                             return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'gmates-p3-4-d':
                                             return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'gmates-p3-5-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p3-6-d':
                                       return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p3-7-d':
                                          return <EditorUploadB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p3-8-d':
                                             return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p3-9-d':
                                       return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                       case 'gmates-p3-10-d':
                                          return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                  
                              
                              
                                 case 'limelight-j2-1-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-2-d':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-3-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-4-d':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-5-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-6-d':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-7-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'limelight-j2-8-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-9-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-10-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-11-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-12-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-13-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-14-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-15-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-16-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-17-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-18-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-19-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-20-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-21-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-22-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-23-d':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-24-d':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                 case 'limelight-j2-25-d':
                                       return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                  
                                 case 'limelight-s2-1-d':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-2-d':
                                                return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-3-d':
                                                return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-4-d':
                                                return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-5-d':
                                                return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-6-d':
                                                return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-7-d':
                                                return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-s2-8-d':
                                                return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-9-d':
                                                return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-10-d':
                                                return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-11-d':
                                                return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-12-d':
                                                return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-13-d':
                                                return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-14-d':
                                                return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-15-d':
                                                return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-16-d':
                                                return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-17-d':
                                                return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-18-d':
                                                return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-19-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-20-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-21-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-22-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-23-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-24-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-25-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-26-d':
                                                      return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-27-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-28-d':
                                                      return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-29-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-s2-30-d':
                                                         return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             
                                                         
                                             case 'limelight-p4-1-d':
                                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-2-d':
                                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-3-d':
                                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-4-d':
                                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-5-d':
                                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-6-d':
                                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-7-d':
                                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                             case 'limelight-p4-8-d':
                                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-9-d':
                                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-10-d':
                                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-11-d':
                                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-12-d':
                                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-13-d':
                                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-14-d':
                                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-15-d':
                                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-16-d':
                                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-17-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-18-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-19-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-20-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-21-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-22-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-23-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-24-d':
                                                   return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-25-d':
                                                   return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-26-d':
                                                      return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-27-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-28-d':
                                                      return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-29-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-30-d':
                                                      return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-31-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-32-d':
                                                         return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                             case 'limelight-p4-33-d':
                                                return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
                                                case 'limelight-p4-34-d':
                                                   return <EditorQuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
                                                   case 'limelight-p4-35-d':
                                                      return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                                            
                     case 'gmates-p4-1-f':
                                 return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                     case 'gmates-p4-2-f':
                                 return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                     case 'gmates-p4-3-f':
                                 return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                     case 'gmates-p4-4-f':
                                 return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                     case 'gmates-p4-5-f':
                        return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                     case 'gmates-p4-6-f':
                        return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p4-7-f':
                           return <EditorQuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} bookName={this.state.bookName} />;
                           case 'gmates-p4-8-f':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                         case 'gmates-p4-9-f':
                          
                         return <EditorQuizC resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
 
                         case 'gmates-p4-10-f':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                    case 'gmates-p4-11-f':
                     return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                             case 'gmates-p4-12-f':
                              return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                              case 'gmates-p4-13-f':
                                 return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'gmates-p4-14-f':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p4-15-f':
                                       return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                           
                                 case 'gmates-p3-1-f':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p3-2-f':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p3-3-f':
                                    return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p3-4-f':
                                    return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p3-5-f':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p3-6-f':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'gmates-p3-7-f':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                              case 'gmates-p3-8-f':
                                 return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                            case 'gmates-p3-9-f':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                              case 'gmates-p3-10-f':
                                 return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'gmates-p3-11-f':
                            return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                  
                           case 'gmates-p3-12-f':
                              return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                              case 'gmates-p3-13-f':
                                 return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                 case 'gmates-p3-14-f':
                                    return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                                    case 'gmates-p3-15-f':
                                       return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                           
                                                
                        case 'gmates-p5-1-f':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-2-f':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-3-f':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-4-f':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-5-f':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-6-f':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-7-f':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-8-f':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'gmates-p5-9-f':
                           return <EditorQuizD resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
                        case 'gmates-p5-10-f':
                           return <EditorQuizD resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
                        case 'gmates-p5-11-f':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                        case 'gmates-p5-12-f':
                           return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                        case 'gmates-p5-13-f':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                        case 'gmates-p5-14-f':
                           return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                        case 'gmates-p5-15-f':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       
                                         
                       case 'goshen-1':
                        return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                          case 'goshen-2':
                        return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'goshen-3':
                        return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                            case 'goshen-4':
                        return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'goshen-5':
                             return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                          case 'goshen-6':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                           case 'goshen-7':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-8':
                        return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                        case 'goshen-9':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-10':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-11':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-12':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-13':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-14':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-15':
                              return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-16':
                              return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-17':
                             return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-18':
                             return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'goshen-19':
                        return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'goshen-20':
                           return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'goshen-21':
                              return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'goshen-22':
                                 return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'goshen-23':
                                    return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'goshen-24':
                                       return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                   

                       case 'camilla-1':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-2':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-3':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-4':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-5':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-6':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-7':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-8':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-9':
                           return <QuizA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-10':
                           return <QuizB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-11':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'camilla-12':
                           return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'camilla-13':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'camilla-14':
                           return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                       case 'camilla-15':
                           return <EditorA taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} bookName={this.state.bookName} />;
                       case 'camilla-16':
                           return <EditorB taskStatus={this.props.onTaskStatusUpdate} htmlChange={this.props.onHtmlChange} height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange} bookName={this.state.bookName} />;
                     
                                        
               }
      	})()}


      	

      
       </div>

    	)	

    }
  


}

export default assessments